import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Formik, Form, Field } from 'formik'
import { login, flashMessage } from 'actions'
import { query, getRelationship } from 'redux-bees'
import { ref, object, string, addMethod } from 'yup'
import valid from 'card-validator'
import { isLoggedIn } from 'selectors'
import { Layout, Input, FormContainer } from 'components'
import { ROUTES } from '../../constants'
import api from 'api'
import {
  urlFor,
  jsonapiToFormErrors,
  getCreditCardOptionsForSelect,
  dashToUnderscoreObject,
  formatDate,
  getProvincesForSelect,
  isLeaders,
} from 'utils'

function creditCardNumber(ref, msg) {
  return this.test({
    name: 'creditCardNumber',
    exclusive: false,
    message: 'Card number is invalid',
    params: {
      reference: ref.path,
    },
    test: function(value) {
      return valid.number(value).isValid
    },
  })
}

function creditCardType(ref, msg) {
  return this.test({
    name: 'creditCardType',
    exclusive: false,
    message: 'Card type does not match number',
    params: {
      reference: ref.path,
    },
    test: function(value) {
      const cardNumber = value
      const cardType = this.resolve(ref)
      switch (cardType) {
        case 'MC':
          return valid.number(cardNumber).isValid && 'mastercard' === valid.number(cardNumber).card.type
        case 'AMX':
          return valid.number(cardNumber).isValid && 'american-express' === valid.number(cardNumber).card.type
        case 'VISA':
          return valid.number(cardNumber).isValid && 'visa' === valid.number(cardNumber).card.type
        case 'DSC':
          return valid.number(cardNumber).isValid && 'discover' === valid.number(cardNumber).card.type
        default:
          return false
      }
    },
  })
}

function creditCardDate() {
  return this.test({
    name: 'creditCardDate',
    exclusive: false,
    message: 'Date is invalid',
    test: function(value) {
      return valid.expirationDate(value).isValid
    },
  })
}

addMethod(string, 'creditCardNumber', creditCardNumber)
addMethod(string, 'creditCardType', creditCardType)
addMethod(string, 'creditCardDate', creditCardDate)

const validationSchema = object().shape({
  amount: string().required(),
  mop: string().required(),
  credit_card_num: string()
    .creditCardNumber(ref('mop'))
    .creditCardType(ref('mop')),
  credit_card_expiry: string().creditCardDate(),
  cvv2: string().required(),
  first_name: string().required(),
  last_name: string().required(),
  address: string().required(),
  city: string().required(),
  state: string().required(),
  zip_code: string().required(),
})

const formatAmount = (value) => {
  if (value !== '' && !isNaN(value)) {
    return parseFloat(value).toFixed(2)
  }
  return value
}

const PaymentForm = ({ onSubmit, registrationId, deposit }) => (
  <Formik
    initialValues={{
      credit_card_num: '',
      mop: '',
      amount: deposit ? formatAmount(deposit) : '',
      credit_card_expiry: '',
      cvv2: '',
      first_name: '',
      last_name: '',
      address: '',
      city: '',
      state: '',
      zip_code: '',
      registration_id: registrationId,
    }}
    validationSchema={validationSchema}
    onSubmit={(values, formOptions) => {
      onSubmit(values, formOptions)
    }}
    render={({ status, values }) => {
      return (
        <Form>
          <div className="form__group">
            <Field component={Input} type="hidden" name="registration_id" />
            <Field component={Input} name="amount" type="number" step="0.01" label="Amount" />
            <Field
              component={Input}
              type="select"
              name="mop"
              options={getCreditCardOptionsForSelect()}
              label="Card Type"
            />
            <Field
              component={Input}
              type="credit-card-number"
              ccType={values.mop}
              name="credit_card_num"
              label="Card Number"
            />
            <Field component={Input} type="credit-card-expiry" name="credit_card_expiry" label="Expiry" />
            <Field
              component={Input}
              type="credit-card-security-code"
              ccType={values.mop}
              name="cvv2"
              label="Security Code"
            />
            <fieldset>
              <legend>Billing information</legend>
              <Field component={Input} name="first_name" label="First Name" />
              <Field component={Input} name="last_name" label="Last Name" />
              <Field component={Input} name="address" label="Address" />
              <Field component={Input} name="city" label="City" />
              <Field component={Input} type="select" name="state" label="Province" options={getProvincesForSelect()} />
              <Field component={Input} name="zip_code" label="Postal Code" />
            </fieldset>
          </div>
          {status && status.apiErrors['base'] && <div className="errors--base">{status.apiErrors['base']}</div>}
          <button type="submit">Pay</button>
          <Link to={urlFor(ROUTES.dashboard, { tab: 'campers' })}>Cancel</Link>
        </Form>
      )
    }}
  />
)

const mapState = state => ({
  isLoggedIn: isLoggedIn(state),
  camper: registration => getRelationship(state, registration, 'camper'),
  ylCamp: registration => getRelationship(state, getRelationship(state, registration, 'area-camp'), 'yl-camp'),
})

const mapDispatch = {
  login: login,
  flash: flashMessage,
}

export default compose(
  connect(
    mapState,
    mapDispatch
  ),
  query('registration', api.getRegistrationByToken, (perform, props) =>
    perform({ token: props.match.params.id, 'include[]': ['area_camp.yl_camp', 'camper'] })
  )
)(
  class NewPayment extends Component {
    onSubmit(values, { setSubmitting, setStatus }) {
      api
        .createPayment({
          payment: values,
        })
        .then(response => {
          this.props.history.push(urlFor(ROUTES.payments.success, { id: response.body.data.id }))
        })
        .catch(err => {
          jsonapiToFormErrors(values, err, setStatus)
          setSubmitting(false)
        })
    }
    render() {
      const { registration, camper, ylCamp } = this.props
      const deposit = new URLSearchParams(this.props.location.search).get('deposit')
      return (
        <Layout>
          <FormContainer>
            {registration && (
              <Fragment>
                {deposit && (
                  <p>
                    You've been redirected to this page to optionally make a deposit of ${Number(deposit).toFixed(2)}. If you prefer to do
                    that in person with your {isLeaders ? 'leader' : 'camper'}, you can{' '}
                    <Link to={urlFor(ROUTES.dashboard, { tab: 'campers' })}>return to the dashboard</Link>.
                  </p>
                )}
                <dl>
                  <dt>{isLeaders ? 'Leader' : 'Camper'}</dt>
                  <dd>
                    {dashToUnderscoreObject(camper(registration).attributes).first_name}{' '}
                    {dashToUnderscoreObject(camper(registration).attributes).last_name}
                  </dd>
                  <dt>Camp</dt>
                  <dd>{dashToUnderscoreObject(ylCamp(registration).attributes).name}</dd>
                  <dt>Camp Dates</dt>
                  <dd>
                    {formatDate(dashToUnderscoreObject(ylCamp(registration).attributes).start_date, 'yyyy-MM-dd')} -{' '}
                    {formatDate(dashToUnderscoreObject(ylCamp(registration).attributes).end_date, 'yyyy-MM-dd')}
                  </dd>
                  <dt>Remaining</dt>
                  <dd>
                    $
                    {(parseFloat(registration.attributes.fee) - parseFloat(registration.attributes.balance)).toFixed(2)}
                  </dd>
                  <dd />
                </dl>
                <h2>Enter a new payment:</h2>
                <PaymentForm
                  registrationId={registration.id}
                  onSubmit={(...args) => this.onSubmit(...args)}
                  deposit={deposit}
                />
              </Fragment>
            )}
          </FormContainer>
        </Layout>
      )
    }
  }
)
