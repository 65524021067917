import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { sortBy, reverse } from 'lodash'
import { ROUTES } from '../../constants'
import { relativeDate, isPaid, formatCurrency, isCreditCardPayment, urlFor } from 'utils'

const DashboardPayments = ({ payments, getRegistration, getAreaCamp }) => {
  const allRegistrations = payments.map(p => getRegistration(p))
  const outstandingBalances = (allRegistrations || []).filter(r => !isPaid(r.attributes.balance, r.attributes.fee))
  return (
    <div>
      {outstandingBalances.length > 0 && (
        <Fragment>
          <h2 className="flex-title">Outstanding Balances</h2>
          <p>You have some registrations that have not been fully paid</p>
          <table className="table-minimal">
            <tbody>
              {outstandingBalances.map((registration, i) => (
                <tr key={i}>
                  <td>
                    <b>{getAreaCamp(registration).attributes.name}</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <p>
                      ${formatCurrency(registration.attributes.balance)}/$
                      {formatCurrency(registration.attributes.fee)} Paid
                    </p>
                    <p>
                      <Link
                        to={urlFor(ROUTES.payments.new, { id: registration.attributes['token'] })}
                        className="btn inline info"
                      >
                        <span className="hide-mobile">Make Payment</span>
                        <span className="hide-desktop">Pay</span>
                      </Link>
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Fragment>
      )}
      <h2 className="flex-title">Your Payments</h2>
      <table className="table-minimal">
        <tbody>
          {payments.length > 0 ? (
            reverse(sortBy(payments, payment => payment.attributes['created-at'])).map(payment => (
              <tr key={payment.id}>
                <td>
                  <strong>
                    {getAreaCamp(getRegistration(payment)) && getAreaCamp(getRegistration(payment)).attributes.name}
                  </strong>
                  <br />
                  <small>
                    <em>{relativeDate(payment.attributes['created-at'])} ago</em>
                  </small>
                </td>
                <td style={{ textAlign: 'right' }}>
                  ${formatCurrency(payment.attributes.amount)}
                  <div>
                    <small>
                      <em>
                        {payment.attributes.paid
                          ? 'Paid'
                          : `Unpaid ${isCreditCardPayment(payment) ? payment.attributes['cc-status'] : ''}`}
                      </em>
                    </small>
                  </div>
                  <div>
                    <Link to={urlFor(ROUTES.payments.show, { id: payment.id })} className="btn gray">
                      View
                    </Link>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3}>
                <li>No payments found.</li>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default DashboardPayments
